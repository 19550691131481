import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6355daa5 = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "pages/About" */))
const _49b453cf = () => interopDefault(import('../pages/Banks.vue' /* webpackChunkName: "pages/Banks" */))
const _58cfd2c0 = () => interopDefault(import('../pages/Buyout.vue' /* webpackChunkName: "pages/Buyout" */))
const _14b83e6b = () => interopDefault(import('../pages/Contacts.vue' /* webpackChunkName: "pages/Contacts" */))
const _5694b7fc = () => interopDefault(import('../pages/Cookie.vue' /* webpackChunkName: "pages/Cookie" */))
const _4117fbd4 = () => interopDefault(import('../pages/Credit/index.vue' /* webpackChunkName: "pages/Credit/index" */))
const _111ba722 = () => interopDefault(import('../pages/Favorites.vue' /* webpackChunkName: "pages/Favorites" */))
const _e283a758 = () => interopDefault(import('../pages/Installment/index.vue' /* webpackChunkName: "pages/Installment/index" */))
const _3ef2a520 = () => interopDefault(import('../pages/Privacy.vue' /* webpackChunkName: "pages/Privacy" */))
const _86bf0424 = () => interopDefault(import('../pages/Reviews1111111.vue' /* webpackChunkName: "pages/Reviews1111111" */))
const _762769e1 = () => interopDefault(import('../pages/Thanks.vue' /* webpackChunkName: "pages/Thanks" */))
const _0e1912c6 = () => interopDefault(import('../pages/Trade-in.vue' /* webpackChunkName: "pages/Trade-in" */))
const _43866260 = () => interopDefault(import('../pages/Credit/_bank/index.vue' /* webpackChunkName: "pages/Credit/_bank/index" */))
const _0b9b958a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _05355f50 = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _77898335 = () => interopDefault(import('../pages/_category/_mark/index.vue' /* webpackChunkName: "pages/_category/_mark/index" */))
const _5c3164cc = () => interopDefault(import('../pages/_category/_mark/_model/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/index" */))
const _404a5a80 = () => interopDefault(import('../pages/_category/_mark/_model/_car/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/_car/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/About",
    component: _6355daa5,
    name: "About"
  }, {
    path: "/Banks",
    component: _49b453cf,
    name: "Banks"
  }, {
    path: "/Buyout",
    component: _58cfd2c0,
    name: "Buyout"
  }, {
    path: "/Contacts",
    component: _14b83e6b,
    name: "Contacts"
  }, {
    path: "/Cookie",
    component: _5694b7fc,
    name: "Cookie"
  }, {
    path: "/Credit",
    component: _4117fbd4,
    name: "Credit"
  }, {
    path: "/Favorites",
    component: _111ba722,
    name: "Favorites"
  }, {
    path: "/Installment",
    component: _e283a758,
    name: "Installment"
  }, {
    path: "/Privacy",
    component: _3ef2a520,
    name: "Privacy"
  }, {
    path: "/Reviews1111111",
    component: _86bf0424,
    name: "Reviews1111111"
  }, {
    path: "/Thanks",
    component: _762769e1,
    name: "Thanks"
  }, {
    path: "/Trade-in",
    component: _0e1912c6,
    name: "Trade-in"
  }, {
    path: "/Credit/:bank",
    component: _43866260,
    name: "Credit-bank"
  }, {
    path: "/",
    component: _0b9b958a,
    name: "index"
  }, {
    path: "/:category",
    component: _05355f50,
    name: "category"
  }, {
    path: "/:category/:mark",
    component: _77898335,
    name: "category-mark"
  }, {
    path: "/:category/:mark/:model",
    component: _5c3164cc,
    name: "category-mark-model"
  }, {
    path: "/:category/:mark/:model/:car",
    component: _404a5a80,
    name: "category-mark-model-car"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
